"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deviceInputSchema = exports.deviceWithTokenSchema = exports.deviceSchema = exports.languageSchema = exports.Language = void 0;
const zod_1 = require("zod");
const services_1 = require("../services");
var Language;
(function (Language) {
    Language["en"] = "en";
    Language["de"] = "de";
})(Language = exports.Language || (exports.Language = {}));
exports.languageSchema = zod_1.z.nativeEnum(Language);
exports.deviceSchema = zod_1.z.object({
    ...services_1.standardAttributes,
    userId: zod_1.z.string().uuid(),
    deviceId: zod_1.z.string().nullable(),
    language: exports.languageSchema,
    model: zod_1.z.string().nullable(),
    appVersion: zod_1.z.string().nullable(),
    appBuild: zod_1.z.string().nullable(),
    platform: zod_1.z.string(),
    osVersion: zod_1.z.string().nullable(),
    isTablet: zod_1.z.boolean().nullable(),
    fcmToken: zod_1.z.string().nullable(),
    lastUsedAt: zod_1.z.date(),
});
(0, services_1.checkSchema)(exports.deviceSchema);
exports.deviceWithTokenSchema = exports.deviceSchema
    .omit({ fcmToken: true })
    .extend({
    fcmToken: zod_1.z.string(),
});
exports.deviceInputSchema = exports.deviceSchema.omit({
    id: true,
    createdAt: true,
    updatedAt: true,
    deletedAt: true,
    userId: true,
    lastUsedAt: true,
});
