"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.platformSchema = exports.Platform = void 0;
const zod_1 = require("zod");
var Platform;
(function (Platform) {
    Platform["IOS"] = "IOS";
    Platform["ANDROID"] = "ANDROID";
})(Platform = exports.Platform || (exports.Platform = {}));
exports.platformSchema = zod_1.z.nativeEnum(Platform);
