"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.USER_BLANK = void 0;
const user_1 = require("./schemas/user");
exports.USER_BLANK = {
    nameFirst: "",
    nameLast: "",
    isAdmin: false,
    email: null,
    geoLocation: null,
    birthdate: null,
    imageId: null,
    phone: null,
    mobile: null,
    iban: null,
    twitter: null,
    xing: null,
    linkedin: null,
    facebook: null,
    instagram: null,
    website: null,
    invisibilityTill: null,
    eventReminderBefore: 86400,
    eventDeadlineReminderBefore: 86400,
    notificationChannels: [user_1.NotificationChannelType.PUSH_NOTIFICATION],
};
