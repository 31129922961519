import React, { ReactNode, useCallback } from "react";
import { useQuery, useLazyQuery } from "@apollo/client";
import { useRouteMatch } from "react-router-dom";
import { User, Device, AuthChannel, IapSubscription } from "shared";
import {
  Breadcrumbs,
  Spinner,
  TBody,
  TR,
  TH,
  TD,
  Table,
  Button,
  useBoolean,
} from "client-lib";
import {
  cutId,
  HeaderCell,
  HeaderRow,
  List,
  ListLinkCell,
  ListRow,
  ParseError,
} from "src/lib";
import { Layout } from "src/features/layout";
import { format } from "date-fns";
import {
  AdminUserEnvironmentQuery,
  AdminUserEnvironmentData,
  AdminUserEnvironmentVars,
  UserToken,
  UserTokenData,
  UserTokenVars,
} from "./queries";
import { UpdateUserModal } from "./user-form";
import { DeleteUserModal } from "./user-form/delete-user-modal";

const columnTemplate = "1fr 1.2fr 1.2fr 1.5fr 1.5fr 2fr";
const iapSubscriptionsColumnTemplate = "1fr 1.2fr 1.2fr 1.5fr";
const authChannelsColumnTemplate = "1fr 1fr 3fr 2fr 1.5fr";

function Wrapper({
  children,
  user,
  userId,
}: {
  children?: ReactNode;
  user?: User;
  userId: string;
}) {
  return (
    <Layout>
      <Breadcrumbs
        items={[
          { title: "Users", url: "/users" },
          { title: user ? `${user.nameFirst} ${user.nameLast}` : `${userId}` },
        ]}
      />
      <h1>
        User{" "}
        {user ? `\u00ab${user.nameFirst} ${user.nameLast}\u00bb` : `${userId}`}
      </h1>
      {children}
    </Layout>
  );
}

export function UserPage() {
  const { params } = useRouteMatch<{ userId: string }>();
  const [isUpdateUserOpen, { set: openUpdateUser, reset: closeUpdateUser }] =
    useBoolean(false);

  const [isDeleteUserOpen, { set: openDeleteUser, reset: closeDeleteUser }] =
    useBoolean(false);

  const { data, loading, error, refetch } = useQuery<
    AdminUserEnvironmentData,
    AdminUserEnvironmentVars
  >(AdminUserEnvironmentQuery, {
    variables: {
      userId: params.userId,
    },
  });

  const [fetchToken] = useLazyQuery<UserTokenData, UserTokenVars>(UserToken, {
    variables: {
      userId: params.userId,
    },
  });

  const handleTempToken = useCallback(() => {
    fetchToken({
      onCompleted: (newTokenData: UserTokenData) => {
        window.location.href = `${process.env.REACT_APP_METAFABRIKA_HOST}/account?token=${newTokenData.UserToken}`;
      },
    });
  }, [fetchToken]);

  if (loading) {
    return (
      <Wrapper userId={params.userId}>
        <Spinner />
      </Wrapper>
    );
  }
  if (error) {
    return (
      <Wrapper userId={params.userId}>
        <ParseError error={error} />
      </Wrapper>
    );
  }
  if (!data) {
    return <Wrapper userId={params.userId} />;
  }

  const { user, authChannels } = data.AdminUserEnvironment;
  return (
    <Wrapper userId={params.userId} user={user}>
      <Table autoWidth>
        <TBody>
          <TR>
            <TH>ID</TH>
            <TD>{user.id}</TD>
          </TR>
          <TR>
            <TH>Email</TH>
            <TD>{user.email}</TD>
          </TR>
          <TR>
            <TH>Created at</TH>
            <TD>{new Date(user.createdAt).toLocaleString()}</TD>
          </TR>
          <TR>
            <TH>First name</TH>
            <TD>{user.nameFirst}</TD>
          </TR>
          <TR>
            <TH>Last name</TH>
            <TD>{user.nameLast}</TD>
          </TR>
          <TR>
            <TH>Address</TH>
            <TD>{user.geoLocation?.address}</TD>
          </TR>
          <TR>
            <TH>Is admin?</TH>
            <TD>{user.isAdmin ? "Yes" : "No"}</TD>
          </TR>
          <TR>
            <TH>Invisibility till</TH>
            <TD>
              {user.invisibilityTill
                ? format(new Date(user.invisibilityTill), "dd.MM.yyyy HH:mm X")
                : "None"}
            </TD>
          </TR>
          <TR>
            <TH>Deleted at</TH>
            <TD>
              {user.deletedAt ? new Date(user.deletedAt).toLocaleString() : ""}
            </TD>
          </TR>
        </TBody>
      </Table>

      <h2>Auth channels</h2>
      <List>
        <HeaderRow columnTemplate={authChannelsColumnTemplate}>
          <HeaderCell>ID</HeaderCell>
          <HeaderCell>Type</HeaderCell>
          <HeaderCell>Key</HeaderCell>
          <HeaderCell>Verified at</HeaderCell>
          <HeaderCell>Is suspended</HeaderCell>
        </HeaderRow>
        {authChannels.map((authChannel: AuthChannel) => {
          const url = `/users/${user.id}/channels/${authChannel.id}`;
          return (
            <ListRow key={user.id} columnTemplate={authChannelsColumnTemplate}>
              <ListLinkCell to={url}>{cutId(authChannel.id)}</ListLinkCell>
              <ListLinkCell to={url}>{authChannel.type}</ListLinkCell>
              <ListLinkCell to={url}>{authChannel.channelKey}</ListLinkCell>
              <ListLinkCell to={url}>
                {authChannel.verifiedAt
                  ? new Date(authChannel.verifiedAt).toLocaleString()
                  : "Not verified"}
              </ListLinkCell>

              <ListLinkCell to={url}>
                {authChannel.isSuspended ? "Yes" : "No"}
              </ListLinkCell>
            </ListRow>
          );
        })}
      </List>

      <h2>Devices</h2>
      <List>
        <HeaderRow columnTemplate={columnTemplate}>
          <HeaderCell>ID</HeaderCell>
          <HeaderCell>Language</HeaderCell>
          <HeaderCell>Platform</HeaderCell>
          <HeaderCell>OS/version</HeaderCell>
          <HeaderCell>App version/build</HeaderCell>
          <HeaderCell>Last used at</HeaderCell>
        </HeaderRow>
        {data.AdminDevices.map((device: Device) => {
          const url = `/users/${user.id}/${device.id}`;
          return (
            <ListRow key={user.id} columnTemplate={columnTemplate}>
              <ListLinkCell to={url}>{cutId(device.id)}</ListLinkCell>
              <ListLinkCell to={url}>{device.language}</ListLinkCell>
              <ListLinkCell to={url}>{device.platform}</ListLinkCell>
              <ListLinkCell to={url}>
                {device.model}/{device.osVersion}
              </ListLinkCell>
              <ListLinkCell to={url}>
                {device.appVersion}/{device.appBuild}
              </ListLinkCell>
              <ListLinkCell to={url}>
                {new Date(device.lastUsedAt).toLocaleString()}
              </ListLinkCell>
            </ListRow>
          );
        })}
      </List>

      <h2>Subscriptions</h2>
      <List>
        <HeaderRow columnTemplate={iapSubscriptionsColumnTemplate}>
          <HeaderCell>ID</HeaderCell>
          <HeaderCell>Type</HeaderCell>
          <HeaderCell>Platform</HeaderCell>
          <HeaderCell>Valid till</HeaderCell>
        </HeaderRow>
        {data.AdminUserEnvironment.iapSubscriptions.map(
          (s: IapSubscription) => {
            const url = `/users/${user.id}`;
            return (
              <ListRow key={user.id} columnTemplate={columnTemplate}>
                <ListLinkCell to={url}>{cutId(s.id)}</ListLinkCell>
                <ListLinkCell to={url}>{s.type}</ListLinkCell>
                <ListLinkCell to={url}>{s.platform}</ListLinkCell>
                <ListLinkCell to={url}>
                  {s.validTill ? new Date(s.validTill).toLocaleString() : ""}
                </ListLinkCell>
              </ListRow>
            );
          }
        )}
      </List>

      <br />
      <Button onClick={openUpdateUser}>Edit user</Button>
      <br />
      <br />
      {isUpdateUserOpen && (
        <UpdateUserModal
          user={user}
          onCloseRequest={closeUpdateUser}
          onUpdated={refetch}
        />
      )}

      <Button onClick={handleTempToken}>Get temporary token</Button>
      <br />
      <br />
      {isDeleteUserOpen && (
        <DeleteUserModal
          user={user}
          onCloseRequest={closeDeleteUser}
          onDeleted={refetch}
        />
      )}
      {!user.deletedAt ? (
        <Button color="secondary" onClick={openDeleteUser}>
          Delete user
        </Button>
      ) : null}
    </Wrapper>
  );
}
