import React from "react";
import styled from "@emotion/styled";
import {
  Button,
  TextField,
  MultilineTextField,
  gutters,
  grey30,
} from "client-lib";
import { Form, Formik, FormikProps } from "formik";
import { emailInputFormikSchema, EmailInput } from "./types";

const Controls = styled.div`
  margin-top: ${gutters.md}px;
  padding-top: ${gutters.md}px;
  border-top: 1px solid ${grey30};
`;

const initialValues: EmailInput = {
  template: "",
  variables: "{}",
};

interface Props {
  onSubmit: (values: EmailInput) => any;
  disabled?: boolean;
  submitTitle?: string;
}

export function EmailForm({ onSubmit, disabled, submitTitle }: Props) {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={emailInputFormikSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ isValid, dirty }: FormikProps<EmailInput>) => (
        <Form>
          <TextField
            label="Template"
            controlProps={{ size: 100 }}
            disabled={disabled}
            name="template"
          />
          <br />
          <MultilineTextField
            label="Variables (JSON)"
            controlProps={{ size: 100 }}
            disabled={disabled}
            name="variables"
          />
          <Controls>
            <Button type="submit" disabled={disabled || !isValid || !dirty}>
              {submitTitle || "Send"}
            </Button>
          </Controls>
        </Form>
      )}
    </Formik>
  );
}
