"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.memberEnvironmentSchema = exports.briefClubMemberhipEnvironmentSchema = exports.briefMemberEnvironmentSchema = exports.briefMemberSchema = exports.EMPTY_MEMBER = exports.memberSchema = exports.membershipSchema = exports.MEMBERSHIP_TYPE_PERMISSION_VALUE = exports.MembershipQueryType = exports.membershipTypeSchema = exports.MembershipType = void 0;
const zod_1 = require("zod");
const services_1 = require("../../services");
const user_1 = require("../user");
const club_1 = require("./club");
const blank_user_1 = require("../../blank-user");
const lodash_1 = require("lodash");
var MembershipType;
(function (MembershipType) {
    MembershipType["NO"] = "NO";
    MembershipType["FOLLOWER"] = "FOLLOWER";
    MembershipType["MEMBER"] = "MEMBER";
    MembershipType["BOARD"] = "BOARD";
    MembershipType["OWNER"] = "OWNER";
})(MembershipType = exports.MembershipType || (exports.MembershipType = {}));
exports.membershipTypeSchema = zod_1.z.nativeEnum(MembershipType);
var MembershipQueryType;
(function (MembershipQueryType) {
    MembershipQueryType["FOLLOWERS"] = "FOLLOWERS";
    MembershipQueryType["MEMBERS_CONFIRMED"] = "MEMBERS_CONFIRMED";
    MembershipQueryType["MEMBERS_UNCONFIRMED"] = "MEMBERS_UNCONFIRMED";
    MembershipQueryType["MEMBERS_ALL"] = "MEMBERS_ALL";
    MembershipQueryType["MEMBERS_OVERDUE"] = "MEMBERS_OVERDUE";
    MembershipQueryType["BOARD_MEMBERS"] = "BOARD_MEMBERS";
    MembershipQueryType["RESPONSIBLE_MEMBERS"] = "RESPONSIBLE_MEMBERS";
})(MembershipQueryType = exports.MembershipQueryType || (exports.MembershipQueryType = {}));
exports.MEMBERSHIP_TYPE_PERMISSION_VALUE = {
    [MembershipType.NO]: 0,
    [MembershipType.FOLLOWER]: 1,
    [MembershipType.MEMBER]: 2,
    [MembershipType.BOARD]: 3,
    [MembershipType.OWNER]: 4,
};
exports.membershipSchema = zod_1.z.object({
    ...services_1.standardAttributes,
    clubId: zod_1.z.string().uuid(),
    userId: zod_1.z.string().uuid(),
    tierId: zod_1.z.string().uuid().nullable(),
    type: exports.membershipTypeSchema,
    // confirmed and actualTill work only for MEMBER type!
    confirmed: zod_1.z.boolean(),
    actualTill: zod_1.z.date().nullable(),
    invisibleTill: zod_1.z.date().nullable(),
    invisibleLeaveClubAfterwards: zod_1.z.boolean(),
});
exports.memberSchema = user_1.userSchema.omit({
    createdAt: true,
    updatedAt: true,
    deletedAt: true,
    isAdmin: true,
    iban: true,
});
exports.EMPTY_MEMBER = {
    ...(0, lodash_1.omit)(blank_user_1.USER_BLANK, "isAdmin", "iban"),
    id: "",
};
const idRe = "[\\da-f]{8}-([\\da-f]{4}-){3}[\\da-f]{12}";
// eslint-disable-next-line no-useless-escape
const membershipIdRegularExpression = new RegExp(`^${idRe}\/${idRe}$`);
exports.briefMemberSchema = exports.memberSchema.pick({
    id: true,
    nameFirst: true,
    nameLast: true,
    imageId: true,
});
// membership environment from club perspective, used to display list of members
exports.briefMemberEnvironmentSchema = zod_1.z.object({
    id: zod_1.z.string().regex(membershipIdRegularExpression),
    briefMember: exports.briefMemberSchema,
    membership: exports.membershipSchema,
});
// member environment from user perspective, used to display list of clubs
exports.briefClubMemberhipEnvironmentSchema = zod_1.z.object({
    id: zod_1.z.string().regex(membershipIdRegularExpression),
    club: club_1.clubSchema,
    membership: exports.membershipSchema,
});
exports.memberEnvironmentSchema = zod_1.z.object({
    id: zod_1.z.string().regex(membershipIdRegularExpression),
    member: exports.memberSchema,
    club: club_1.clubSchema,
    membership: exports.membershipSchema,
    clubs: zod_1.z.array(exports.briefClubMemberhipEnvironmentSchema),
});
