"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.adminUserEnvironmentSchema = exports.tokenUserSchema = exports.userPatchSchema = exports.userAdminPatchSchema = exports.userAdminInputSchema = exports.userSchema = exports.notificationChannelTypeSchema = exports.NotificationChannelType = void 0;
const zod_1 = require("zod");
const services_1 = require("../services");
const geo_location_1 = require("./geo-location");
const auth_channel_1 = require("./auth/auth-channel");
const iap_1 = require("./iap");
var NotificationChannelType;
(function (NotificationChannelType) {
    NotificationChannelType["PUSH_NOTIFICATION"] = "PUSH_NOTIFICATION";
    NotificationChannelType["EMAIL"] = "EMAIL";
})(NotificationChannelType = exports.NotificationChannelType || (exports.NotificationChannelType = {}));
exports.notificationChannelTypeSchema = zod_1.z.nativeEnum(NotificationChannelType);
exports.userSchema = zod_1.z.object({
    ...services_1.standardAttributes,
    nameFirst: zod_1.z.string(),
    nameLast: zod_1.z.string(),
    isAdmin: zod_1.z.boolean(),
    email: zod_1.z.string().email().nullable(),
    geoLocation: geo_location_1.geoLocationSchema.nullable(),
    birthdate: zod_1.z.date().nullable(),
    imageId: zod_1.z.string().nullable(),
    eventDeadlineReminderBefore: zod_1.z.number().int().nullable(),
    eventReminderBefore: zod_1.z.number().int().nullable(),
    notificationChannels: zod_1.z.array(exports.notificationChannelTypeSchema).nullable(),
    phone: zod_1.z
        .string()
        .regex(/^\+41\s\d{3}\s\d{3}\s\d{3}$/)
        .nullable(),
    mobile: zod_1.z
        .string()
        .regex(/^\+41\s\d{3}\s\d{3}\s\d{3}$/)
        .nullable(),
    iban: zod_1.z
        .string()
        .regex(/^CH\d{2}\s\d{4}\s\d{4}\s\d{4}\s\d{4}\s\d$/)
        .nullable(),
    twitter: zod_1.z.string().nullable(),
    xing: zod_1.z.string().nullable(),
    linkedin: zod_1.z.string().nullable(),
    facebook: zod_1.z.string().nullable(),
    instagram: zod_1.z.string().nullable(),
    website: zod_1.z.string().url().nullable(),
    invisibilityTill: zod_1.z.date().nullable(),
});
(0, services_1.checkSchema)(exports.userSchema);
exports.userAdminInputSchema = exports.userSchema.omit({
    createdAt: true,
    updatedAt: true,
});
exports.userAdminPatchSchema = exports.userAdminInputSchema.omit({
    id: true,
});
exports.userPatchSchema = exports.userSchema.omit({
    id: true,
    createdAt: true,
    updatedAt: true,
    deletedAt: true,
    isAdmin: true,
    email: true,
    invisibilityTill: true,
});
exports.tokenUserSchema = exports.userSchema.pick({
    id: true,
    nameFirst: true,
    nameLast: true,
    isAdmin: true,
    imageId: true,
});
exports.adminUserEnvironmentSchema = zod_1.z.object({
    id: zod_1.z.string().uuid(),
    user: exports.userSchema,
    authChannels: zod_1.z.array(auth_channel_1.authChannelSchema),
    iapSubscriptions: zod_1.z.array(iap_1.iapSubscriptionSchema),
});
