"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.iapSubscriptionTransactionSchema = void 0;
const zod_1 = require("zod");
const services_1 = require("../../services");
exports.iapSubscriptionTransactionSchema = zod_1.z.object({
    ...services_1.standardAttributes,
    iapSubscriptionId: zod_1.z.string().uuid(),
    platformId: zod_1.z.string(),
    platformData: zod_1.z.string(),
    validTill: zod_1.z.date(),
});
(0, services_1.checkSchema)(exports.iapSubscriptionTransactionSchema);
