import React, { ReactNode, useCallback } from "react";
import { useQuery } from "@apollo/client";
import { useRouteMatch } from "react-router-dom";
import { User } from "shared";
import {
  Breadcrumbs,
  Spinner,
  TBody,
  TR,
  TH,
  TD,
  Table,
  useBoolean,
  Button,
} from "client-lib";
import { ParseError } from "src/lib";
import { Layout } from "src/features/layout";
import { format } from "date-fns";
import { AdminDevice, AdminDeviceData, AdminDeviceVars } from "./queries";
import { NotificationModal } from "./notification-form";

function Wrapper({
  children,
  user,
  userId,
  deviceId,
}: {
  children?: ReactNode;
  user?: User;
  userId: string;
  deviceId: string;
}) {
  return (
    <Layout>
      <Breadcrumbs
        items={[
          { title: "Users", url: "/users" },
          {
            title: user ? `${user.nameFirst} ${user.nameLast}` : `${userId}`,
            url: `/users/${userId}`,
          },
          { title: deviceId },
        ]}
      />
      <h1>
        Device #{deviceId} of user{" "}
        {user ? `\u00ab${user.nameFirst} ${user.nameLast}\u00bb` : `${userId}`}
      </h1>
      {children}
    </Layout>
  );
}

export function DevicePage() {
  const { params } = useRouteMatch<{ userId: string; deviceId: string }>();
  const { data, loading, error } = useQuery<AdminDeviceData, AdminDeviceVars>(
    AdminDevice,
    {
      variables: {
        userId: params.userId,
        deviceId: params.deviceId,
      },
    }
  );
  const [
    isSendNotificationOpen,
    { set: openSendNotification, reset: closeSendNotification },
  ] = useBoolean(false);

  const handleSent = useCallback((result: string | null) => {
    if (result) {
      alert(result);
    } else {
      alert("Queued!");
    }
  }, []);

  if (loading) {
    return (
      <Wrapper userId={params.userId} deviceId={params.deviceId}>
        <Spinner />
      </Wrapper>
    );
  }
  if (error) {
    return (
      <Wrapper userId={params.userId} deviceId={params.deviceId}>
        <ParseError error={error} />
      </Wrapper>
    );
  }
  if (!data) {
    return <Wrapper userId={params.userId} deviceId={params.deviceId} />;
  }

  const {
    AdminDevice: device,
    AdminUserEnvironment: { user },
  } = data;
  return (
    <Wrapper userId={params.userId} deviceId={params.deviceId} user={user}>
      <Table autoWidth>
        <TBody>
          <TR>
            <TH>ID</TH>
            <TD>{device.id}</TD>
          </TR>
          <TR>
            <TH>Language</TH>
            <TD>{device.language}</TD>
          </TR>
          <TR>
            <TH>Platform</TH>
            <TD>{device.platform}</TD>
          </TR>
          <TR>
            <TH>Model</TH>
            <TD>{device.model}</TD>
          </TR>
          <TR>
            <TH>OS version</TH>
            <TD>{device.osVersion}</TD>
          </TR>
          <TR>
            <TH>App version</TH>
            <TD>{device.appVersion}</TD>
          </TR>
          <TR>
            <TH>Build number</TH>
            <TD>{device.appBuild}</TD>
          </TR>
          <TR>
            <TH>Is tablet</TH>
            <TD>{device.isTablet ? "Yes" : "No"}</TD>
          </TR>
          <TR>
            <TH>FCM token</TH>
            <TD>{device.fcmToken}</TD>
          </TR>
          <TR>
            <TH>Last used at</TH>
            <TD>{format(new Date(device.lastUsedAt), "dd.MM.yyyy HH:mm X")}</TD>
          </TR>
        </TBody>
      </Table>
      {isSendNotificationOpen && (
        <NotificationModal
          deviceId={params.deviceId}
          onCloseRequest={closeSendNotification}
          onSent={handleSent}
        />
      )}
      <br />
      <Button onClick={openSendNotification}>Send notification</Button>
    </Wrapper>
  );
}
