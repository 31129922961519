"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.workerTaskSchema = exports.WORKER_TASK_DATA_SCHEMA = exports.wtInvisibilityOverDataSchema = exports.wtSendServiceCodeEmailDataSchema = exports.wtSendEmailDataSchema = exports.wtPushNotificationDataSchema = exports.workerTaskTypeTypeSchema = exports.WorkerTaskType = void 0;
const zod_1 = require("zod");
const notification_1 = require("./notification");
var WorkerTaskType;
(function (WorkerTaskType) {
    WorkerTaskType["PUSH_NOTIFICATION"] = "PUSH_NOTIFICATION";
    WorkerTaskType["SEND_EMAIL"] = "SEND_EMAIL";
    WorkerTaskType["SEND_SERVICE_CODE_EMAIL"] = "SEND_SERVICE_CODE_EMAIL";
    WorkerTaskType["INVISIBILITY_OVER"] = "INVISIBILITY_OVER";
})(WorkerTaskType = exports.WorkerTaskType || (exports.WorkerTaskType = {}));
exports.workerTaskTypeTypeSchema = zod_1.z.nativeEnum(WorkerTaskType);
exports.wtPushNotificationDataSchema = zod_1.z.object({
    fcmToken: zod_1.z.string(),
    notification: notification_1.notificationSchema,
});
exports.wtSendEmailDataSchema = zod_1.z.object({
    template: zod_1.z.string(),
    email: zod_1.z.string().email(),
    variables: zod_1.z.object({}).passthrough(),
});
exports.wtSendServiceCodeEmailDataSchema = zod_1.z.object({
    template: zod_1.z.string(),
    serviceCodeId: zod_1.z.string().uuid(),
});
exports.wtInvisibilityOverDataSchema = zod_1.z.object({
    userId: zod_1.z.string(),
});
exports.WORKER_TASK_DATA_SCHEMA = {
    [WorkerTaskType.PUSH_NOTIFICATION]: exports.wtPushNotificationDataSchema,
    [WorkerTaskType.SEND_EMAIL]: exports.wtSendEmailDataSchema,
    [WorkerTaskType.SEND_SERVICE_CODE_EMAIL]: exports.wtSendServiceCodeEmailDataSchema,
    [WorkerTaskType.INVISIBILITY_OVER]: exports.wtInvisibilityOverDataSchema,
};
exports.workerTaskSchema = zod_1.z.object({
    type: exports.workerTaskTypeTypeSchema,
    data: zod_1.z.object({}).passthrough(),
});
