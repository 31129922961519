"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.clubPostFileInputSchema = exports.clubPostFileSchema = exports.clubContentPrivacySchema = exports.ClubPostFileType = void 0;
const zod_1 = require("zod");
var ClubPostFileType;
(function (ClubPostFileType) {
    ClubPostFileType["IMAGE"] = "IMAGE";
    ClubPostFileType["DOCUMENT"] = "DOCUMENT";
})(ClubPostFileType = exports.ClubPostFileType || (exports.ClubPostFileType = {}));
exports.clubContentPrivacySchema = zod_1.z.nativeEnum(ClubPostFileType);
exports.clubPostFileSchema = zod_1.z.object({
    clubPostId: zod_1.z.string().uuid(),
    fileId: zod_1.z.string().uuid(),
    type: exports.clubContentPrivacySchema,
    description: zod_1.z.string(),
    originalFileName: zod_1.z.string(),
});
exports.clubPostFileInputSchema = exports.clubPostFileSchema.pick({
    fileId: true,
    type: true,
    description: true,
    originalFileName: true,
});
