"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.serviceCodeMetaSchema = exports.serviceCodeSchema = exports.serviceCodeStatusSchema = exports.ServiceCodeStatus = exports.SERVICE_CODE_TIMEOUTS = exports.serviceCodeTypeSchema = exports.ServiceCodeType = void 0;
const zod_1 = require("zod");
const services_1 = require("../../services");
var ServiceCodeType;
(function (ServiceCodeType) {
    ServiceCodeType["EMAIL_VERIFICATION"] = "EMAIL_VERIFICATION";
    ServiceCodeType["PASSWORD_RECOVERY"] = "PASSWORD_RECOVERY";
})(ServiceCodeType = exports.ServiceCodeType || (exports.ServiceCodeType = {}));
exports.serviceCodeTypeSchema = zod_1.z.nativeEnum(ServiceCodeType);
exports.SERVICE_CODE_TIMEOUTS = {
    [ServiceCodeType.EMAIL_VERIFICATION]: {
        regeneration: 2 * 60,
        valid: 2 * 60,
    },
    [ServiceCodeType.PASSWORD_RECOVERY]: {
        regeneration: 60,
        valid: 60,
    },
};
var ServiceCodeStatus;
(function (ServiceCodeStatus) {
    ServiceCodeStatus["SENT"] = "SENT";
    ServiceCodeStatus["USED"] = "USED";
    ServiceCodeStatus["EXPIRED"] = "EXPIRED";
})(ServiceCodeStatus = exports.ServiceCodeStatus || (exports.ServiceCodeStatus = {}));
exports.serviceCodeStatusSchema = zod_1.z.nativeEnum(ServiceCodeStatus);
exports.serviceCodeSchema = zod_1.z.object({
    ...services_1.standardAttributes,
    type: exports.serviceCodeTypeSchema,
    authChannelId: zod_1.z.string().uuid(),
    sentAt: zod_1.z.date().nullable(),
    validTill: zod_1.z.date(),
    status: exports.serviceCodeStatusSchema,
    code: zod_1.z.string(),
});
(0, services_1.checkSchema)(exports.serviceCodeSchema);
exports.serviceCodeMetaSchema = zod_1.z.object({
    validTill: zod_1.z.date(),
    canRegenerateAt: zod_1.z.date(),
});
