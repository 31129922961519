import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { UsersPage } from "./users-page";
import { UserPage } from "./user-page";
import { AuthChannelPage } from "./auth-channel-page";
import { DevicePage } from "./device-page";

export function UserRouter() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={UsersPage} />
      <Route exact path={`${path}/:userId`} component={UserPage} />
      <Route
        exact
        path={`${path}/:userId/channels/:authChannelId`}
        component={AuthChannelPage}
      />
      <Route exact path={`${path}/:userId/:deviceId`} component={DevicePage} />
    </Switch>
  );
}
