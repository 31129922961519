"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.applePublicKeysResponseSchema = exports.applePublicKeySchema = exports.appleTokenSchema = exports.appleAuthInputSchema = void 0;
const zod_1 = require("zod");
exports.appleAuthInputSchema = zod_1.z.object({
    identityToken: zod_1.z.string(),
    nameFirst: zod_1.z.string(),
    nameLast: zod_1.z.string(),
    email: zod_1.z.string().nullable(),
});
exports.appleTokenSchema = zod_1.z.object({
    iss: zod_1.z.string().url(),
    aud: zod_1.z.string(),
    exp: zod_1.z.number(),
    iat: zod_1.z.number(),
    sub: zod_1.z.string(),
    nonce: zod_1.z.string().optional(),
    c_hash: zod_1.z.string().optional(),
    email: zod_1.z.string().email().optional(),
    email_verified: zod_1.z.union([zod_1.z.boolean(), zod_1.z.string()]).optional(),
    auth_time: zod_1.z.number().optional(),
    nonce_supported: zod_1.z.boolean().optional(),
    is_private_email: zod_1.z.union([zod_1.z.boolean(), zod_1.z.string()]).optional(),
});
exports.applePublicKeySchema = zod_1.z.object({
    kty: zod_1.z.literal("RSA"),
    kid: zod_1.z.string(),
    use: zod_1.z.literal("sig"),
    alg: zod_1.z.literal("RS256"),
    sub: zod_1.z.string(),
    n: zod_1.z.string(),
    e: zod_1.z.string(), // Exponent (base64url-encoded)
});
exports.applePublicKeysResponseSchema = zod_1.z.object({
    keys: zod_1.z.array(exports.applePublicKeySchema),
});
