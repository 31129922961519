import React, { useCallback } from "react";
import { useMutation } from "@apollo/client";
import { ParseError } from "src/lib";
import { Modal } from "client-lib";
import { EmailForm } from "./email-form";
import {
  AdminSendEmail,
  AdminSendEmailVars,
  AdminSendEmailData,
} from "../queries";
import { EmailInput } from "./types";

interface Props {
  authChannelId: string;
  onCloseRequest: () => any;
  onSent?: (error: string | null) => any;
}

export function EmailModal({ authChannelId, onCloseRequest, onSent }: Props) {
  const [update, { loading, error }] = useMutation<
    AdminSendEmailData,
    AdminSendEmailVars
  >(AdminSendEmail);

  const handleSubmit = useCallback(
    async (input: EmailInput) => {
      const result = await update({
        variables: {
          authChannelId,
          ...input,
        },
      });
      onSent?.(result.data!.AdminSendEmail);
      onCloseRequest();
    },
    [update, onCloseRequest, authChannelId, onSent]
  );

  return (
    <Modal onCloseRequest={onCloseRequest} title="Send email" minWidth={500}>
      {error ? <ParseError error={error} /> : null}
      <EmailForm
        disabled={loading}
        onSubmit={handleSubmit}
        submitTitle="Send"
      />
    </Modal>
  );
}
