import { z } from "shared";
import { toFormikSchema } from "client-lib";

export const emailInput = z.object({
  template: z.string().min(1),
  variables: z.string().min(2),
});

export type EmailInput = z.infer<typeof emailInput>;

export const emailInputFormikSchema = toFormikSchema(emailInput);
