"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.visibilitySchema = exports.VISIBILITY_TO_MEMBER_FIELD = exports.visibilityFieldSchema = exports.VisibilityField = void 0;
const zod_1 = require("zod");
const membership_1 = require("./club/membership");
var VisibilityField;
(function (VisibilityField) {
    VisibilityField["EMAIL"] = "EMAIL";
    VisibilityField["GEO_LOCATION"] = "GEO_LOCATION";
    VisibilityField["BIRTHDATE"] = "BIRTHDATE";
    VisibilityField["PHONE"] = "PHONE";
    VisibilityField["MOBILE"] = "MOBILE";
    VisibilityField["TWITTER"] = "TWITTER";
    VisibilityField["XING"] = "XING";
    VisibilityField["LINKEDIN"] = "LINKEDIN";
    VisibilityField["FACEBOOK"] = "FACEBOOK";
    VisibilityField["INSTAGRAM"] = "INSTAGRAM";
    VisibilityField["WEBSITE"] = "WEBSITE";
})(VisibilityField = exports.VisibilityField || (exports.VisibilityField = {}));
exports.visibilityFieldSchema = zod_1.z.nativeEnum(VisibilityField);
exports.VISIBILITY_TO_MEMBER_FIELD = {
    [VisibilityField.EMAIL]: "email",
    [VisibilityField.GEO_LOCATION]: "geoLocation",
    [VisibilityField.BIRTHDATE]: "birthdate",
    [VisibilityField.PHONE]: "phone",
    [VisibilityField.MOBILE]: "mobile",
    [VisibilityField.TWITTER]: "twitter",
    [VisibilityField.XING]: "xing",
    [VisibilityField.LINKEDIN]: "linkedin",
    [VisibilityField.FACEBOOK]: "facebook",
    [VisibilityField.INSTAGRAM]: "instagram",
    [VisibilityField.WEBSITE]: "website",
};
exports.visibilitySchema = zod_1.z.object({
    userId: zod_1.z.string().uuid(),
    clubId: zod_1.z.string().uuid().nullable(),
    visibilityField: exports.visibilityFieldSchema.nullable(),
    membershipType: membership_1.membershipTypeSchema,
});
