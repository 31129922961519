"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.googleTokenSchema = exports.googleUserSchema = void 0;
const zod_1 = require("zod");
exports.googleUserSchema = zod_1.z.object({
    id: zod_1.z.string(),
    email: zod_1.z.string().email(),
    name: zod_1.z.string().optional().nullable(),
    photo: zod_1.z.string().url().optional().nullable(),
    familyName: zod_1.z.string().optional().nullable(),
    givenName: zod_1.z.string().optional().nullable(),
});
exports.googleTokenSchema = zod_1.z.object({
    iss: zod_1.z.string().url(),
    azp: zod_1.z.string(),
    aud: zod_1.z.string(),
    sub: zod_1.z.string(),
    email: zod_1.z.string().email(),
    email_verified: zod_1.z.boolean(),
    at_hash: zod_1.z.string().nullable().optional(),
    name: zod_1.z.string(),
    picture: zod_1.z.string().url().nullable().optional(),
    given_name: zod_1.z.string().nullable().optional(),
    family_name: zod_1.z.string().nullable().optional(),
    locale: zod_1.z.string().nullable().optional(),
    iat: zod_1.z.number(),
    exp: zod_1.z.number(),
    jti: zod_1.z.string().nullable().optional(),
});
